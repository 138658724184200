import React from "react"
import KVPVideo from "../../videos/kylie.mp4"
import Layout from "../../components/layout"

import { Typography, Container, Grid, Button,makeStyles } from "@material-ui/core"

const useStyles=makeStyles(theme=>({
    margins:{
        marginBottom:"2rem",
        [theme.breakpoints.down('xs')]:{
            marginBottom:"-0.5rem"
        }
    }
}))
export default function Video(data) {
    const classes=useStyles()
  return (
    <Layout
      showNav={false}
      menuData={data.data.allSanitySiteConfig.edges[0].node}
      disableScroll={true}
    >

      <Container maxWidth="lg">
        <Grid direction="column" container justify="center" alignContent="center" alignItems="center" spacing={2} >
          <Grid item xs={12} className={classes.margins}>
            <Typography align="center" variant="h2" color="primary">
              Let's connect
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="body1" style={{marginBottom:"1.5rem"}}>Hi Kylie,</Typography>
            <Typography align="center" variant="body1">
              so great you got in touch!
              <br />Please watch the short video below.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{height:"300px"}} className={classes.margins}>
            <video src={KVPVideo} width="100%" height="100%" controls />
          </Grid>
          <Grid item xs={12} style={{marginBottom:"2rem"}}>
          <Button  size="large" variant="contained" color="primary" href="https://app.acuityscheduling.com/schedule.php?owner=19917864"><Typography variant="body1" align="center"> Book your free consultation</Typography></Button>
</Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityPage(title: { eq: "Home" }) {
      description
      title
      shownav
      _rawContent(resolveReferences: { maxDepth: 20 })
      content {
        ... on SanityGallery {
          _key
          _type
          description
          title
          fullWidth: images {
            alt
            caption
            asset {
              fluid(maxWidth: 3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          thumbnails: images {
            alt
            caption
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityImageSection {
          _key
          _type
          image {
            alt
            caption
            asset {
              fluid(maxWidth: 3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityHero {
          _key
          _type
          backgroundImage {
            alt
            caption
            asset {
              fluid(maxWidth: 3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityInfocards {
          _key
          _type
          cards {
            _key
            image {
              alt
              caption
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        ... on SanityTextOnImageSection {
          _key
          _type
          image {
            alt
            caption
            asset {
              fluid(maxWidth: 3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          imageMobile {
            alt
            caption
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityProductsList {
          _key
          _type
          _rawProducts(resolveReferences: { maxDepth: 20 })
          products {
            image {
              alt
              caption
              asset {
                fluid(maxWidth: 1000) {
                  ...GatsbySanityImageFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    allSanitySiteConfig {
      edges {
        node {
          id
          contactemail
          contactphone
          title
          mainNavigation {
            slug {
              current
            }
            page {
              ... on SanityPage {
                id
                title
              }
            }
          }
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
